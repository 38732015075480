/* Navbar.css */
.navbar {
  background-color: #0C3B3A;
  color: #fff;
  padding: 25px 0;
  position: relative;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 0px 100px;
  width: 100%;
}

.navbar-logo img {
  width: 250px;
  padding: 0px;
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #D0AE83;
}

.navbar-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-item {
  margin-right: 20px;
}

.navbar-link {
  text-decoration: none;
  transition: color 0.3s;
  color: #D0AE83;
}


.instant-valuation a {
  border: 1px solid #D0AE83;
  padding: 10px;
  border-radius: 50px;
}

.dropdown.show {
  margin-top: 9px;
  margin-left: 8px;
  width: 96.88px;
}

/* .dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: auto; 
} */

.pdropdown {
  margin-left: -12px;
  margin-top: -15px;
  display: grid;
  place-items: center left;
  gap: 4px;
  background: #0C3B3A;
  border: 1px solid #D0AE83;
  padding: 9px;
  padding-left: 10px;
  padding-right: 32px;
  position: absolute;
  border-radius: 18px;
  overflow-y: hidden;
  max-height: 34px; 
  transition: max-height 0.5s ease; 
  cursor: pointer;
}

.pdropdown.expanded {
  max-height: 55px; 
}

.pdropdown .option {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  line-height: 1.3 !important;
  height: 18px;
}

.pdropdown .option svg {
  width: 16px;
  height: 16px;
}

.navbar-link:hover {
  color: #ff9900;
  text-decoration: none !important;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
}

.bar {
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin: 3px 0;
  transition: transform 0.3s, opacity 0.3s;
}

.navbar-toggle.active .bar:first-child {
  transform: translateY(7px) rotate(45deg);
}

.navbar-toggle.active .bar:nth-child(2) {
  opacity: 0;
}

.navbar-toggle.active .bar:last-child {
  transform: translateY(-7px) rotate(-45deg);
}



#offcanvasRight {
  max-width: 70%;
  /* Adjust the percentage to your desired width */
  width: 80%;
  background: #0C3B3A;
}

.offcanvas-navbar-desktop-menu {
  margin-top: -12px;
  background: transparent;
  color: #FFF;
  border: none;
  /* margin-left: 120px; */
  font-size: 25px;

}

.navbar-offcanvas-close-btn {
  position: absolute;
  bottom: 300px;
  left: -40px;
  width: 90px !important;
  height: 90px !important;
  border: 2px solid #D0AE83;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:50px;
  color:#D0AE83;
  cursor: pointer;
}

.btn-close {

  margin-top: 10px;
  font-size: 40px;


}


/* .btn-close {
 
  border: 2px solid red;
  right:120px ;
} */

/* .offcanvas-header .btn-close {
 
  font-size: 24px;
  font-weight: bold;

} */

.offcanvas-body img {
  width: 300px;

}

.accordion-item {
  background-color: transparent !important;
  color: #D0AE83;
}

.accordion-item h2 button {
  text-align: right;
  background-color: transparent !important;
  font-size: 25px;
  font-family: var(--playfair-font);
  font-style: normal;
  font-weight: 400;
  color: #fff;

}
.offcanvas-body ul a{
  text-decoration: none;
}
.offcanvas-body ul {
  margin-right: 100px;
  color: #FFF;
  text-align: right;
  font-family: Playfair Display;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.offcanvas-body li {
  list-style: none;
  color: #D0AE83;
  text-align: right;
  font-family: Playfair Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

/* .offcanvas-body ul li {
  display: none;
} */


.offcanvas-body ul:focus li {
  display: list-item;
}


.offcanvas-body ul {
  cursor: pointer;
}


.navbar-logo-header img {
  width: 200px;
}

.Menubar-Heading {
  margin-right: 100px;
  gap: 10px;
  display: flex;
  justify-content: end;
  align-items: baseline;
}
.Menubar-Heading p {
  color: #FFF;
  margin-top: 35px;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.offcanvas-body-nav {
  margin-left: 50px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.offcanvas-body-footer {
  color: #FFF;
  text-align: center;
  font-family: Playfair Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

@media (min-width: 768px) {
  .navbar-logo-header,.nav-web {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar-container {
    align-items: center;
    margin: 0px;
    padding: 0px 20px;
  }

  .nav-responsive {
    display: none;
  }
  .navbar-logo {
    display: none;
  }

  .navbar-desktop-menu {
    display: none;

  }

  .navbar-links {
    display: none;
  }

  .navbar-left {
    display: none;
  }

  .navbar-right {
    display: none;
  }

  .navbar-links.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #333;
    top: 60px;
    left: 0;
    right: 0;
    opacity: 0.95;
    z-index: 1;
  }

  .navbar-links.active .navbar-list {
    flex-direction: column;
    align-items: center;
  }

  .navbar-item {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .navbar-toggle {
    display: flex;
    flex-direction: column;
  }

  .navbar-toggle.active .bar:first-child {
    transform: translateY(7px) rotate(45deg);
  }

  .navbar-toggle.active .bar:nth-child(2) {
    opacity: 0;
  }

  .navbar-toggle.active .bar:last-child {
    transform: translateY(-7px) rotate(-45deg);
  }

}