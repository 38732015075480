.main{
    margin-bottom: 80px;
    width: 90%;
}
.main img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main h2{
    color: #0C3B3A;
    padding-bottom: 10px;
    border-bottom: 5px solid #D0AE83 !important;
}
.main div:nth-child(2) p{
    font-size: 12px;
}
.main div:nth-child(2) div{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main div:nth-child(2) img{
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.profile-card{
    transition: all 0.5s ease-in-out;
}
.profile-card:hover{
    transform: scale(1.07);
}
.profile-card .img{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.profile-card .img:hover .social-icon{
    display: block;
}

.profile-card .name h5{
    margin: 0px;
}
.profile-card .name{
    padding: 10px 0px;

    background-color: #D0AE83;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.social{
    display: flex;
    align-items: center;
    justify-content: center;

}
.social-icon{
    display: none;
    text-align: center;
    height: 30px;
    width: 30px;
    margin: 5px;
    background-color: #fff;
    color: #D0AE83;
    cursor: pointer;
}


.ourteam-footer {
    background: #0C3B3A;
    margin-top: 120px;
   }