.customer-carosal-bg {
  position: relative;
  /* flex-direction: column; */
  text-align: center;
  border: 1px solid #d0ae83;
  padding: 50px 10px;
  /* height: 350px; */
  color: #fff;
  background-color: #d0ae83;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.customer-carosal-desc {
  position: relative;
  padding-bottom: 15px;
  font-size: 14px !important;
}
.customer-carosal-bg .customer-carosal-quotes {
  position: absolute;
  color: #00000054;
  top: 25px !important;
  left: 10;
  font-size: 40px;
}
.customer-carosal-circle {
  height: 50px !important;
  width: 50px !important;
  background: #d4d4d4;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 20px;
}

.customer-carosal-name {
  color: var(--White, #fff) !important;
  text-align: center !important;
  font-family: "Playfair Display" !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  /* margin-bottom: 0px !important; */
}


.customer-carosal-arrow-icon {
  width: 45px;
  background-color: #D0AE83;
  font-size: 40px;
  /* padding: 20px; */
  color: #0C3B3A;
}
