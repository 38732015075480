.footer-container {
  width: 100%;
  padding: 0px 100px;
  margin: auto;
}

.contactUs-wrapper {
  padding: 150px 0px 80px 0px;
  position: relative;
}

.footer-contactUs-topIcon {
  position: absolute;
  right: -25px;
  top: 140px;
}

.contactUs-bottomIcon {
  position: absolute;
  right: 10px;
  bottom: 115px;
}
.contactUs-bottomIcon img {
  width: 150px;
}

.contactUs-wrapper h1 {
  color: var(--white, #f8fbfc);
  font-family: "Merriweather" !important;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 160%; */
  margin-left: 20px !important;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.contactUs-wrapper {
  display: flex;
  align-items: center;
  /* margin-bottom: 50px; */
}

.contactUs-btn {
  display: flex;
  justify-content: end;
  padding: 150px 0px 80px 0px;
}

.contactUs-btn button {
  outline: none;
  border: none;
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--brand, #45aaf0);
  color: #fff;
  box-shadow: 0px 16px 56px 0px rgba(69, 170, 240, 0.2);
}
.contactUs-btn a {
  text-decoration: none !important;
}
.contactUs-btn a:hover {
  text-decoration: none !important;
}

.social-wrapper p {
  margin: 20px 0px;
}

.social-content-wrapper p {
  color: #fff !important;
  margin: 15px 0px;
  font-size: 15px;
  font-style: normal;
}

.footer-subtitle h3 {
  color: #d0ae83;
  font-family: "Playfair Display" !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 20px;
  width: 260px;
  text-transform: uppercase;
  margin-top: 25px;
}

.footer-subtitle ul {
  padding: 0px;
}

.footer-subtitle ul li {
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  list-style: none;
  text-decoration: none !important;
}
.footer-subtitle ul li a {
  color: var(--white, #f8fbfc);
  text-decoration: none !important;
}

.important-links h3 {
  color: #d0ae83;
  font-family: "Playfair Display" !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 20px;
  align-self: stretch;
  margin-top: 25px;
}
.important-links p {
  color: #fff;
  font-family: Inter;
  font-weight: 400;
  line-height: normal;
  line-height: 21px;
}

.important-links ul {
  padding: 0px;
}

.important-links ul li {
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  list-style: none;
  margin-bottom: 10px;
  text-decoration: none !important;
}
.important-links ul li a {
  color: var(--white, #f8fbfc);
  text-decoration: none !important;
}

.footer-terms-links ul {
  padding: 0px;
  display: flex;
  gap: 18px;
  margin-bottom: 0px !important;
}

.footer-terms-links ul li {
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  list-style: none;
  text-decoration: none !important;
}
.footer-terms-links ul li a {
  color: var(--white, #f8fbfc);
  text-decoration: none !important;
}

.footer-logo-wrapper {
  display: flex;
  justify-content: center;
}

.footer-social-icons {
  gap: 20px;
  display: flex;
  color: #45aaf0;
  justify-content: end;
}
.footer-sociallinks-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
}

@media (max-width: 768px) {
  .contactUs-wrapper {
    padding: 30px 0px;
    flex-wrap: wrap;
    gap: 15px;
  }
  

  .contactUs-wrapper h1 {
    width: 280px;
  }

  .contactUs-btn {
    display: flex;
    justify-content: start;
    padding: 40px 15px;
  }

  .footer-content-wrapper {
    max-width: 296px;
    /* display: flex; */
    margin-left: 5px;
    gap: 20px;
  }

  .class2 {
    margin-left: 10px;
  }

  .footer-contactUs-topIcon {
    right: 0px;
    top: 130px;
  }

  .contactUs-bottomIcon {
    right: 75px;
    bottom: 65px;
  }

  .contactUs-wrapper img {
    margin-top: 90px;
  }

  .contactUs-wrapper h1 {
    width: 280px;
    margin-top: 20px;
  }
}
