.career-top-card-wrapper {
  padding-left: 100px;
  margin: 50px 0px;
  display: flex;
  align-items: center;
}

.career-top-card h6 {
  color: #d0ae83;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.career-top-card span {
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}

.career-top-card p {
  margin-top: 10px;
  color: #888;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  width: 300px;
}

.career-top-card button {
  background: #d0ae83;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 15px;
  width: 150px;
  height: 30px;
}

.career-top-card-image img {
  width: 100%;
}

.career-center-card h1 {
  text-align: center;
  margin-top: 55px;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  /* 140% */
}

.career-card-center-section {
  margin-top: 50px;
  background: rgba(12, 59, 58, 0.03);
}

.career-card-detail {
  background: #fff;
}

.career-card-detail h4 {
  color: #2c3c4d;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 125% */
}

.view-all-job-btn {
  background: transparent;
  border: none;
  color: #d0ae83;
}

.current-job-section-btn {
  background: transparent;
  border: none;
  color: #d0ae83;
  border: 1px solid #d0ae83;
  font-size: 20px;
  padding: 8px 25px;
  border-radius: 8px;
}
.current-job-section-btn:hover {
  background: #d0ae83;
  color: #fff;
}

.join-us-heading {
  background: #f8f9f9;
}

.join-us-heading h2 {
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  /* 140% */
}

.career-join-us-section li {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 104.167% */
}
.career-join-us-section-image img {
  width: 100%;
}

.career-join-us-section-wrapper {
  padding-left: 100px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
}

.careers-footer {
  background: #0c3b3a;
  margin-top: 120px;
}

.career-apply-modal-heading h4 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  padding-top: 50px;
}

.apply-now-from input {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #d0ae83;
  padding-left: 20px;
  height: 60px;
}

.career-submit-btn {
  background: #d0ae83;
  border: none;
  color: #fff;
  width: 200px;
  height: 40px;
  border-radius: 7px;
  font-size: 20px;
}
.career-submit-btn:hover {
  background: #d0ae83;
  color: #fff;
}
.career-apply-modal .modal-dialog {
  min-width: 1000px !important;
}
