.terms-main {
  margin-bottom: 80px;
  width: 90%;
}
.terms-main img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.terms-main p {
  margin-top: 30px;
  color: #0c3b3a;
  padding-top: 20px;
  border-top: 5px solid #d0ae83 !important;
}
.terms-main div:nth-child(2) p {
  font-size: 12px;
}
.terms-main div:nth-child(2) div {
  margin-top: 50px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.terms-main div:nth-child(2) img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
