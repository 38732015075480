
.main-container {
  width: 100%;
  padding: 0px 100px;
  margin: auto;
}

.main-header {
  background: url("../../..//public/assets/home-banner.png");
  background-size: cover;
  height: auto;
  background-repeat: no-repeat;
  background-position: center center;
}


.background {
  background: #0c3b3a;
  /* height: 200vh; */
}

.header#masthead {
  background-color: #0c3b3a;
  padding: 15px 0px;
}

.homepage-banner-heading h1 {
  width: 595px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: "Playfair Display" !important;
  font-size: 70px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  margin-bottom: 25px;
}

.homepage-fillbutton a {
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0) !important;
  background: #d0ae83;
  color: #fff;
  font-family: "Playfair Display" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  padding: 18px 35px;
}
.homepage-emptybutton a {
  border-radius: 30px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  font-family: "Playfair Display" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  padding: 18px 35px;
}
.homepage-emptybutton a:hover {
  background: #d0ae83;
}

.homepage-banner-btn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

#search-autocomplete {
  width: 90%;
  max-width: 420px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 25px;
  margin: 50px 0px;
  display: flex;
  align-items: center;
}

#search-autocomplete input {
  border-radius: 30px;
  border: none;
  background: none;
  outline: none;
  color: #fff;
  font-family: Playfair Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 3px 20px;
  height: 44px;
  width: 80%;
  font-family: var(--playfair-font);
}

#search-autocomplete input::placeholder {
  color: #fff;
  font-size: 14px;
}

.search-button {
  background: none;
  color: #fff;
  border: none;
  font-size: 18px;
  margin-left: 35px;
}

.homepage-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
}

.banner-cards-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  flex-wrap: wrap;
}
.banner-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner-cards img {
  background: #d0ae83;
  padding: 40px 30px;
}

.banner-cards h3 {
  color: #fff;
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  letter-spacing: 2px;
}

.homepage-carousal-heading {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.homepage-carousal-heading h2 {
  width: 263px;
  color: #d0ae83;
  text-align: center;
  font-family: Playfair Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.prompt-consultation-img {
  position: relative;
}
.prompt-consultation-img img {
  width: 100%;
}

.overlay_action {
  position: absolute;
  z-index: 2;
  background: linear-gradient(90deg, #0c3b3a 17.5%, rgba(12, 59, 58, 0) 90.44%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.prompt-consultation-inputs input {
  border: none;
  border-bottom: 1px solid #d0ae83 !important;
  background: none;
  outline: none !important;
  color: #fff;
  font-size: 14px;
  padding-bottom: 5px;
}
.prompt-consultation-inputs input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.prompt-consultation-btn {
  width: 100%;
}

.prompt-consultation-btn a {
  background: #d0ae83;
  color: #fff;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.48px;
  padding: 10px 60px 10px 60px;
}

.prompt-consultation-content h2 {
  color: var(--White, #fff);
  font-family: Playfair Display;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 62.4px */
  text-transform: capitalize;
}
.prompt-consultation-content p {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.UAEdeveloper-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.UAEdeveloper-content h2 {
  width: 500px;
  color: #fff;
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
}

.UAEdeveloper-content p {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.UAEdeveloper-img {
  padding: 0px;
}
.UAEdeveloper-img img {
  width: 100%;
}
.UAEdeveloper-wrapper {
  border: 1px solid #d0ae83;
  margin: 100px 0px !important;
}

.UAEdeveloper-damac {
  border: 1px solid #d0ae83;
  padding: 20px 10px;
}
.UAEdeveloper-damac-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.UAEdeveloper-damac-btn {
  margin-left: 25px;
  margin: 15px 0px;
}
.UAEdeveloper-damac-btn a {
  border: 1px solid #d0ae83;
  padding: 7px 22px;
  color: #d0ae83;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

.customer-testimonial-wrapper h3 {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.customer-testimonial-wrapper h2 {
  color: #d0ae83;
  text-align: center;
  font-family: Playfair Display;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 30px;
}

.offProperty-plan-heading h2 {
  color: #d0ae83;
  text-align: center;
  font-family: "Playfair Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.offProperty-plan-img img {
  width: 100%;
  height: 407px;
}

.offProperty-plan-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.offProperty-plan-content h3 {
  color: #fff;
  font-family: "Playfair Display";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
}
.offProperty-plan-content p {
  color: #fff;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  margin-bottom: 0px;
}

.offProperty-plan-price p {
  color: #d0ae83;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}

.offProperty-plan-bgImg{
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 149px;
}

.offProperty-plan-price-bg {
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: center;
}
.offProperty-plan-price-bg p {
  background: #d0ae83;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 87px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.offProperty-plan-btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.offProperty-plan-btn a {
  border: 1px solid #d0ae83;
  padding: 15px 22px;
  color: #d0ae83;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}







.real-estate-container {
  margin-top: 80px !important;
  background: #052221;
  width: 100%;
  padding: 60px 100px !important; 
  margin: auto;
}
.real-estate-content {
}
.real-estate-content h2{
  color: #D0AE83;
  font-family: Playfair Display;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  width: 441px;
  margin-bottom: 20px;
}

.real-estate-content p{
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 382px;
  margin-bottom: 50px;
}

.real-estate-content a{
  border: 1px solid #d0ae83;
  padding: 15px 22px;
  color: #d0ae83;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}


@media (max-width: 768px) {
  .main-container {
    width: 100%;
    padding: 0px 30px !important;
    margin: auto;
  }
}
