.contact-header {
  background: url("../../..//public/assets/contact-headImg.png");
  background-size: cover !important;
  height: auto;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  /* height: 100%; */
}

.contact-background {
  background: #0c3b3a;
  /* height: 200vh; */
}

.contact-map-img {
  padding: 0px 50px 80px 0px;
}
.contact-map-img img {
  width: 100%;
}

.contact-header-content-wrapper {
  display: flex;
  justify-content: center;
  padding: 100px 20px;
  /* position: relative; */
  /* top: -400px; */
}
.contact-header-content-bg {
  border: 1px solid #d0ae83;
  background: linear-gradient(
    270deg,
    rgba(12, 59, 58, 0.95) 11.73%,
    rgba(12, 61, 60, 0.95) 139.93%
  );
  backdrop-filter: blur(2px);
  width: 1150px;
}

.contact-back-btn {
  display: flex;
  align-items: center;
}
.contact-back-btn a {
  border: none !important;
  /* padding: 7px 22px; */
  color: #d0ae83;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  outline: none;
}

.contact-left-content {
  padding: 20px 45px !important;
}

.contact-left-content-btnwrapper {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.contact-left-content h2 {
  width: 400px;
  color: #fff;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  margin-top: 10px;
}
.contact-left-content p {
  width: 380px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 0px;
}

.contact-call-btn a {
  border: none !important;
  padding: 8px 60px 8px 12px;
  border-radius: 20px;
  background: #d0ae83;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none !important;
  outline: none;
}
.contact-call-btn a span {
  margin-top: 15px !important;
}

.contact-whatsapp-btn a {
  border: none !important;
  padding: 8px 20px 8px 12px;
  border-radius: 20px;
  background: #36b37e;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none !important;
  outline: none;
}

.contact-email-btn a {
  border: none !important;
  padding: 8px 60px 8px 12px;
  border-radius: 20px;
  background: #183b56;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none !important;
  outline: none;
}

.contact-img-wrapper {
  height: 380px;
}

.contact-img-wrapper img {
  width: 100%;
  position: relative;
  top: -60px;
  height: 445px;
}

.contact-right-content {
  padding: 30px 20px;
}

.contact-name-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}
.contact-name-wrapper h4 {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.contact-address-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.contact-address-wrapper p {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 10px;
  letter-spacing: 2px;
}

.contact-company-name h2 {
  color: #fff;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  padding: 10px 0px;
}

.location-map iframe {
  width: 100% !important;
  height: 350px;
}
