.listing-page-main{
    margin-bottom: 80px;
    width: 90%;
}
.listing-page-main img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.listing-page-main h2{
    color: #0C3B3A;
    padding-bottom: 10px;
    border-bottom: 5px solid #D0AE83 !important;
}
.listing-page-main div:nth-child(2) p{
    font-size: 12px;
}
.listing-page-main div:nth-child(2) div{
    margin-top: 20px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.listing-page-main div:nth-child(2) img{
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.listing-page-form {
    margin-top: 50px;
    border: 1px solid #D0AE83;
    border-radius: 20px;
    padding: 20px;
}

.listing-page-form label {
    font-weight: bold;
    margin-bottom: 10px;
}

.listing-page-form select {
    display: block;
    border: none;
    outline: none;
    color: #ABABAB;
}
.listing-page-search-btnn {
    font-size: 25px;
    text-align: center;
    color: #fff;
    background-color: #D0AE83;
    border: none;
    height: 100%;
    width: 140%;
    margin-left: 20px;
}
.listing-vl {
    border-left: 1px solid #C4C4C4;
}
.listing-card{
    padding: 0;
    border: 1px solid #D0AE83;
    border-radius: 10px;
}
.listing-page-card-img{
    /* margin-top: 30px; */
    position: relative;
    /* background-image: url(../../assets/PageFinal/Mask1.png); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 220px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
   }
   .popular-search p{
    margin: 0;
   }
   .popular-search{
    margin: 30px 0px;
    padding: 10px 0px;
    background-color: #E3E3E3;
    text-align: center;
   }
   .for-sale-card{
    border: 1px solid #D0AE83;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 30px;
   }
   .for-sale-card p{
    font-size: 13px;
    margin: 0;
   }
  