
.text-theme {
  color: #d0ae83;
}
.bg-theme {
  background-color: #d0ae83;
}
.border-theme {
  border-color: #d0ae83 !important;
}

.go-back p {
  color: #000;
}
.header img {
  width: 100%;
}
.view-all-photos-img {
  background-image: url("../../../public/assets/Thumbnail1.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45%;
  border-radius: 10px;
}
.view-all-photos-btn {
  border: none;
  background-color: #fff;
  font-weight: bold;
  padding: 10px;
  border-radius: 30px;
}
.property-card img {
  margin-bottom: 10px;
}
.property-detail {
  border-radius: 15px;
  margin-top: 20px;
  border: 1px solid #ebf7fb;
  padding: 15px 5px;
}
.attributre {
  display: inline;
  font-size: 14px;
}
.property-card {
  padding: 0;
}
.custom-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  padding-top: 20px;
  padding-right: 10px;
  border: 1px solid #d0ae83;
  height: 150px;
}
.custom-card p {
  text-align: center;
}
.contact-us-box {
  margin-top: 20px;
  border: 1px solid #ebf7fb;
  border-radius: 15px;
  padding: 20px;
}
.contact-us-box p {
  margin: 0px;
}
.contact-us-box h4 {
  display: inline;
  margin-right: 10px;
}
.contact-us-box img {
  width: 25px;
  margin-bottom: 10px;
}
.contact-us-box .contact-us-btn {
  width: 100%;
  margin-top: 20px;
  border: none;
  background-color: #0c3b3a;
  color: #fff;
  font-weight: bold;
  padding: 10px;
  border-radius: 30px;
}
.side-bar-right {
  padding: 30px;
}
.similar-property-card {
  margin-top: 30px;
  position: relative;
  background-image: url(../../../public/assets/Mask1.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 220px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 15px;
}
.aed {
  position: absolute;
  top: 10;
  margin: 20px;
  text-align: center;
  background: #d0ae83;
  border-radius: 30px;
  color: #fff;
  width: 65%;
  padding: 5px;
}
.aed p {
  margin: 0;
  font-weight: bold;
}
.faq {
  padding: 30px;
  /* background-color: #E3E3E3; */
}

.faq h3 {
  color: #000;
  text-align: center;
  font-family: "Inter";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

.buyproperty-footer {
  background: #0c3b3a;
  margin-top: 120px;
}




.property-modal .modal-dialog img{
    width: 100% !important;
   }
   .property-modal .modal-dialog{
    margin-top: 200px;
   }
   .property-modal .modal-dialog .btn-close1{
    position: absolute;
    z-index: 111 !important;
    bottom:  -30px !important;
    right: 0px !important;
    font-size: 20px !important;
    color: #fff !important;
    background: transparent !important;
    border: none;
    cursor: pointer;
   }
   .property-modal .modal-dialog .modal-content {
    border: none;
    margin-top: 160px;
    animation: scale-up 0.7s ease-in-out;
    transform-origin: center center;
    transform: scale(2);
    background: none;
  }
  @keyframes scale-up {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: scale(2);
    }
  }
  .property-modal .carousel-control-next-icon ,  .carousel-control-prev-icon{
    width: 15px !important;
  }

.property-modal .modal-dialog img {
  width: 100%;
}
/* .property-modal .modal-content {
  background: none;
  border: none;
} */

.property-modal .carousel {
margin: 0px;
width: 100%;
}



.property-modal .modal-dialog .btn-close1 {
  position: absolute;
  z-index: 111;
  bottom: -35px;
  right: 10px;
  font-size: 25px;
  color: #fff !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
