.dubai-main{
    margin-bottom: 80px;
    width: 90%;
}
.dubai-main img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dubai-main p{
    color: #0C3B3A;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 5px solid #D0AE83 !important;
}
.dubai-main div:nth-child(2) p{
    font-size: 12px;
}
.dubai-main div:nth-child(2) div{
    margin-top: 50px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.dubai-main div:nth-child(2) img{
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.dubai{
    margin: 20px 0px;
}
.dubai-img{
    border-radius: 10px 10px 0px 0px;
    /* background-image: url(../../assets/Arealist/project.png); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 250px;
}
.dubai-content{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top: 0px !important;
    border: 1px solid #D0AE83;
    border-radius: 0px 0px 10px 10px;
}
.dubai-content button{
    margin-top: 10px;
    border: 1px solid #D0AE83;
    background: transparent;
    border-radius: 10px;
    color: #D0AE83;
    padding: 5px 10px;
    transition: all 0.5s ease-in-out;

}
.dubai-content button:hover{
    background-color: #D0AE83;
    color: #fff;
}
.dubai-avaible-project-card{
    margin-top: 30px;
    position: relative;
    /* background-image: url(../../assets/PageFinal/Mask1.png); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 220px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 15px;
   }




   .dubaiarea-guide-footer {
    background: #0c3b3a;
    margin-top: 120px;
  }
  