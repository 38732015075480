
.instant-valuation-img img{
    width: 100%;
    height: 100%;
}
.instant-valuation-content{
    padding-right: 80px;
}
/* .instant-valuation-page.instant-valuation-img{
    background-image: url('../../assets/InstantValuation/hotel.png') !important;
    background-position: center center !important;
    background-repeat: no-repeat;
    background-size: cover !important;
} */
.instant-valuation-page select , .instant-valuation-page input, .instant-valuation-page textarea{

    width: 100%;
    color: #0C3B3A;
    border: 1px solid #D0AE83;
    border-radius: 5px;
    padding: 5px 15px;
}
.instant-valuation-page select:focus, .instant-valuation-page input:focus{
    outline: none;
}
.instant-valuation-page a{
    border: none ;
    color: #000;
}
.instant-valuation-page p{
    font-size: 13px;
}
.instant-valuation-page .submit-btn{
    width: 100%;
    background-color: #D0AE83;
    color: #fff;
    border: none;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
}
.instant-valuation-page .submit-btn:hover{
    background-color: #cc9957;
 
}
.images{
    position: relative;
    margin-top: 30px;
}
.images img{
    width: 60px;
}
.images p{
   margin: 0;

}
.images .img2{
    position: absolute;
    left: 60px;
}
.images .img3{
    position: absolute;
    left: 110px;
}


.instant-valuation-footer {
    background: #0c3b3a;
  }
  



@media (max-width:768px) {
    .instant-valuation-content{
        margin: 40px;
    }

    
}
