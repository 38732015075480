.project-card-top-btn {
    background: transparent;
    border: none;
    color: #D0AE83;
}

.project-card-top-section h2 {
    color: var(--neutral-5, #333);
    font-family: Inter;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    /* 163.636% */
}

.project-card-top-section p {
    color: var(--neutral-4, #5C5C5C);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.table th {
    background: #D0AE83;
    color: #FFF;
    text-align: center;
}

.table td {
    text-align: center;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    /* 150% */
}

.table tr td:nth-child(1) {
    background: rgba(208, 174, 131, 0.17);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.sobha-group-heading {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}