

.buyer-guide-heading-wrapper {
    padding-left: 100px;
    margin: 60px 0px;
}
.buyer-guide-heading h2 {
    color: #000;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    margin-bottom: 0px;
}

.buyer-guide-heading span {
    color: #888;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 0px;
}

.buyer-guide-heading p {
    color: #888;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    width: 325px;
    /* 150% */
}

.properties-sale-btn {
    border-radius: 20px;
    background: #D0AE83;
    border: none;
    color: #fff;
    font-size: 12px;
    padding: 10px 20px;
}



.buyer-guide-contact-btn {
    border-radius: 20px;
    background: #D0AE83;
    border: none;
    color: #fff;
    font-size: 12px;
    padding: 10px 20px;
}

.real-state-image img {
    width: 100%;
}

.buyer-guide-paragraph p {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.buyer-guide-third-card h4 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    /* 125% */
}
.buyer-guide-third-card p
{
 color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px;
}
.buyer-guide-help-btn{
    margin-top: 100px;
    border-radius: 20px;
    background: #D0AE83;
    border: none;
    color: #fff;
    font-size: 10px;
    padding: 10px 20px;
}
.buyer-guide-house-image img {
    width: 100%;
}
.buyer-guide-last-card{
    margin-top: 20px;
}
.buyer-guide-last-card h4 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    /* 125% */
}
.buyer-guide-last-card p
{
 color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px;
}


.buyer-guide-footer {
    background: #0c3b3a;
    margin-top: 120px;
}