body {
    font-family: Inter;
}

.main {
    margin-bottom: 80px;
    width: 90%;
}

.main img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blog-click-top-section h2 {

    margin-top: 100px;
    color: #0C3B3A;
    font-family: Inter;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    width: 307px;
    /* 100% */
}
.blog-click-top-section hr {
    border: 2px solid #d0ae83 !important;
    opacity: 100%;
    width: 320px;
   
}

.blog-click-top-section p {
    color: #000;
    margin-top: 10px;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 70px;
    /* 291.667% */
}
.calender-icon{
  
}


.blog-click-top-section span {
    
   
    color: #000;
    margin-top: 10px;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 70px;
    /* 291.667% */
}

.blog-click-main-box img {

    width: 100%;
    height: 400px;
}



.blog-click-main-box p {
    margin-top: 10px;
    color: #343434;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    /* 183.333% */
}

.grey-box {
    background: #C4C4C4;
    width: 149px;
    height: 99px;
    flex-shrink: 0;
}

.recent-post-content-wrapper {
    display: flex;
    align-items: center;
}

.recent-post-content {
    margin-left: 20px;
}

.recent-post-content h3 {
    margin-top: 20px;
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    /* 150% */
}

.recent-post-content p {
    color: #A9A9A9;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 33px;
    margin-top: 19px;
    /* 183.333% */
}

.click-blog-heading {
    color: #000;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 51px;
    /* 141.667% */
}

.blog-click-second-section h2 {
    color: #000;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 51px;
    /* 141.667% */
    margin-top: 20px;
}

.blog-click-second-section p {
    width: 500px;
    margin-top: 20px;

}

.blog-click-second-section img {
    width: 500px;
    margin-top: 20px;

}

.blog-click-third-box {
    display: flex;
    margin-top: 30px;
}

.blog-click-third-box p {
    color: #343434;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 33px;
    /* 183.333% */
}

.blog-click-third-box h6 {
    margin-top: 3px;
    
    color: #343434;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    margin-left: 65px;
    /* 183.333% */
}

.blog-click-third-box span {
    margin-left: 150px;
    margin-top: 7px;
}

.blog-click-third-box img {
    width: 20px;
    margin-top: 10px;
    margin-left: 10px;
}