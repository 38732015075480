*{
    padding: 0;
    margin: 0;
  
  }
  
  .box{
    position: relative;
    /* flex-direction: column; */
    /* text-align: center; */
    border-radius: 20px;
    border: 1px solid #D0AE83;
    /* padding: 170px 0px; */
    height: 350px;
    color: #fff;
    background-image: url(../../../public/assets/apartmentImg1.png);
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
  .carousel{
    width: 90%;
    margin: 50px;
  }
  .slick-prev:before,.slick-next:before{
    color: black !important;
    display: none;
  }
  
  .slick-slide{
    /* margin: 10px; */
    /* width: 352px !important; */
  }
  
  .slick-prev{
    left: -60px !important;
  }
  .slick-next{
    right: -40px !important;
  }
  
  .cardd{
    padding-top: 20px;
    width: 100%;
    background: linear-gradient(179deg, #08302F 3.5%, #154E4D 94.01%);
    position: absolute;
    bottom: 0;
    border-radius: 20px;
  }
  .property-carousal-appartment{
    top: 0;
    /* position: absolute; */
    padding: 5px ;
    font-size: 12px;
    border-radius: 10px;
    border: 1px solid #D0AE83;
    background: #D0AE83;
    width: 30%;
    margin: 20px;
    text-align: center;
  }


  .aed{
    font-weight: bold;
    background: #D0AE83;
    color: #0C3B3A;
    padding: 6px;
    border-radius: 20px 0px 0px 20px ;
    font-size:16px;
  }
 .property-carosal-price{
    font-weight: bold;
    background: #D0AE83;
    color: #0C3B3A;
    padding: 6px;
    border-radius: 20px 0px 0px 20px ;
    font-size:16px;
  }


  .icon{
    color: #D0AE83  ;
    font-size: 14px;
  }
  .icon p{
    display: inline;
    color: #fff;
    font-size: 13px;
    margin-left: 5px;
    
  }
  .box hr{
    opacity: 1;
    color:#D0AE83;
    margin: 0;
  }
  .phone,.email,.whatsapp{
    display: inline;
    padding: 10px 15px;
  }
  .phone{
    border-top: 0px !important;
    border-bottom: 0px !important;
    border: 1px solid #D0AE83;
    border-radius: 20px 0px 0px 0px ;
  }
  .email{
   
    border-left: 1px solid #D0AE83;
    border-radius: 0px 0px 20px 0px ;
  }
  .themeicon{
    color: #D0AE83;
  }
  
  .react-multi-carousel-item {
    /* margin: 0px 3px; */
    width: 100%;
  }
  .slick-slide > div {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }
  .custom-arrow-icon {
    width: 45px;
    background-color: #D0AE83;
    font-size: 40px;
    /* padding: 20px; */
    color: #0C3B3A;
    
  }
  .rotate {
    transform: rotate(180deg); 
  }
  .slick-slider {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0px 20px 0px 25px;
  }
  