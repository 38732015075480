.main-container {
  width: 100%;
  padding: 0px 100px;
  margin: auto;
}

.blog-header {
  background: url("../../..//public/assets/blog-headImg.png");
  background-size: cover;
  height: auto;
  background-repeat: no-repeat;
  background-position: center center;
}

.blog-heading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}
.blog-heading-wrapper hr {
  border: 2px solid #d0ae83 !important;
  opacity: 100%;
  width: 200px;
}
.blog-banner-heading {
  display: flex;
}
.blog-banner-heading h1 {
  color: #fff;
  font-family: Inter;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
}

.blog-headerContent-img {
  padding: 0px;
}
.blog-headerContent-img img {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  height: 100%;
}
.blog-header-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog-header-wrapper {
  position: relative;
  top: 90px;
  border: 1px solid #d0ae83;
  /* margin: 100px 0px !important; */
  background: #fff;
  border-radius: 10px;
  width: 1000px;
}

.blog-headerContent-wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 30px 60px !important;
  /* align-items: center; */
}

.blog-header-content h2 {
  color: #052221;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 133.333% */
  letter-spacing: 0.2px;
  width: 380px;
}

.blog-header-content p {
  width: 400px;
  color: #5a7184;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.blog-header-content h3 {
  background: #9ce0c4;
  border-radius: 50px;
  padding: 8px;
  width: 30%;
  text-align: center;
  /* opacity: 50%; */
  color: #0c3b3a !important;
  font-size: 20px;
}

.blog-header-avarter-wrapper {
  display: flex;
}
.blog-headerContent-avarter img {
  width: 50px;
}

.blog-top-section {
  margin-top: 150px;
}
.blog-whatsap-icon img {
  float: right;
  width: 50px;
  margin-top: -15px;
}

.top-card-img-1 {
  background-image: url("../../../public/assets/jumeriah.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 17px;
  position: relative;
}

.top-card-img-2 {
  background-image: url("../../../public/assets/dubai-banner.png");

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 17px;
  position: relative;
}

.top-card-heading {
  margin-top: 5px;
  margin-left: 22px;
  color: #fff;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  /* 133.333% */
  letter-spacing: 0.2px;
}

.card-img-1 {
  /* background-image: url("../../../public/assets/jumeriah.png"); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 17px;
  position: relative;
}

.card-img-2 {
  background-image: url("../../../public/assets/dubai-banner.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 17px;
  position: relative;
}

.card-img-3 {
  background-image: url("../../../public/assets/jumeriah.png");

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 17px;
  position: relative;
}

.heading-primary {
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.2px;
}

.p-primary {
  color: #5a7184;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  width: 450px;
}

.blog-view-btn {
  background: transparent;
  color: #d0ae83;
  height: 30px;
  width: 100px;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 1px solid #d0ae83;
  border-radius: 7px;
  margin-top: 50px;
}
.blog-view-btn:hover{
  background: #d0ae83;
  color: #FFF;
}

.top-card-liner {
  background: linear-gradient(180deg, rgba(24, 59, 86, 0) 0%, #152532 100%);
  height: 400px;
  width: 100%;
  border-radius: 17px;
}

.liner {
  background: linear-gradient(180deg, rgba(24, 59, 86, 0) 0%, #152532 100%);
  height: 350px;
  width: 100%;
  border-radius: 17px;
}

.blog-card-body {
  position: absolute;
  bottom: 0;
  color: #fff;
}

.blog-card-body h6 {
  margin-top: 5px;
  margin-left: 22px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blog-card-body p {
  margin-top: 12px;
  margin-left: 10px;
  color: #fff;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.p-secondary {
  margin-left: 10px;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.writer-p {
  font-size: 13px;
  text-align: center;
}

.verified-icon {
  width: 20px;
  /* margin-left: 10px; */
}

.card-date {
  color: #fff;
  text-align: right;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.blogs-footer {
  background: #0c3b3a;
  margin-top: 120px;
}
