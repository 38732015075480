

.about-us-header {
    background: url("../../..//public/assets/blog-headImg.png");
    background-size: cover;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
  }
  .about-us-liner{
    background: linear-gradient(182deg, #0C3B3A 21.73%, rgba(12, 59, 58, 0.00) 103.64%);
  }

  .About-us-section {
    border: 1px solid #D0AE83;
    background: linear-gradient(270deg, rgba(12, 59, 58, 0.95) 11.73%, rgba(12, 61, 60, 0.95) 139.93%);
    backdrop-filter: blur(2px);
}

.left-arrow{
    color: #D0AE83;
    margin-left: 20px;
    
}


.About-us-section h1 {
    color: #FFF;
    margin-left: 20px;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 94px;
    /* 134.286% */
}

.About-us-section p {
    color: #FFF;
  
    font-size: 15px;
    font-style: normal;
    margin-left: 20px;
    line-height: 30px;
    width: 700px;
    font-weight: 300;

}
.about-us-image{
    width: 320px;
    margin-top: -50px;
}
.about-us-center-box{
    border-radius: 10px;
}
.about-us-center-block{
    background: #0C3B3A;
}

.about-us-center-block-heading {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 25px;
}
.about-us-center-block-heading h4{
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.about-us-center-block-discription p{
    color: #FFF;
    
    font-size: 16px;
    font-style: normal;
    
    
}
.About-us-third-box h4{
    color: #000;
text-align: center;
font-family: Inter;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 56px; /* 140% */
}
.About-us-third-box p
{
    color: #888;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 150% */
}
.about-us-first-card h6{
    margin-top: 20px;
    color: #000;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.about-us-first-card {
    min-height: 395px;
}