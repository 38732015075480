.offplan-header {
    background-image: url(../../../public/assets/header.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;

}

.offplan-header p {
    margin: 30px 0px;
    font-size: 13px;
    text-align: center;
    width: 40%;
}

.offplan-header h1 {
    font-size: 60px;
}

    .read-more {
    background-color: transparent;
    padding: 10px 20px;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 30px;
}

.offplan-header .read-more:hover {
    border: 1px solid #D0AE83;
    background: #D0AE83;
}



.vl {
    border-left: 1px solid #C4C4C4;

    padding-left: 3%;
}

.offplan-form {
    margin-top: 50px;
    border: 1px solid #D0AE83;
    border-radius: 20px;
    padding: 20px;
}

.offplan-form label {
    font-weight: bold;
    margin-bottom: 10px;
}

.offplan-form select {
    display: block;
    border: none;
    outline: none;
    color: #ABABAB;
}

.select_box {
    width: 200px;
    overflow: hidden;
    position: relative;
}

.select_box:after {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #D0AE83;
    position: absolute;
    top: 40%;
    right: 5px;
    content: "";
    z-index: 98;
}

.select_box select {
    width: 220px;
    border: 0;
    position: relative;
    z-index: 99;
    background: none;
}

.search-btnn {
    font-size: 25px;
    text-align: center;
    color: #fff;
    background-color: #D0AE83;
    border: none;
    height: 100%;
    width: 100%;
}

.offplan-card {
    height: 300px;
    margin-right: 20px;
}

.offplan-card .offplan-card-img {
    height: 300px;
    border-radius: 10px 0px 0px 10px;
    /* background-image: url(../../../public/assets/hotel.png); */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.offplan-card-content:hover .read-more {
    border-color: #fff;
    color: #fff;
}

.offplan-card-content:hover p, .offplan-card-content:hover h5 {

    color: #fff;
}

.offplan-card-content:hover {
    background-color: #D0AE83;
}

.offplan-card-content {
    background-image: url(../../../public/assets/Vector.png);
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid #C4C4C4;
    border-radius: 0px 10px 10px 0px;
    background-color: #F8F9FA;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s ease-in;
}

.offplan-card-content p {

    margin-top: 30px;
    width: 80%;
    text-align: center;
    font-size: 10px;
    transition: all 0.5s ease-in;
}

.offplan-card-content h5 {
    font-weight: bold;
    transition: all 0.5s ease-in;
}

.offplan-card-content .read-more {
    margin-top: 30px;
    border-color: #D0AE83;
    color: #D0AE83;
    padding: 5px 20px;
    transition: all 0.5s ease-in;
}



.investment-footer {
    background: #0c3b3a;
    margin-top: 120px;
  }
  